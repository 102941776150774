import { Fragment, useEffect, useRef, useState } from "react";
import { QRCode } from "../components/QRCode";
import { useSocket } from "../socket";
import { Box, Fade, Paper, styled, useTheme } from "@mui/material";
import { Icon } from "@iconify/react";
import Cookie from "js-cookie";
import Image from "next/image";

type CodeResponse = {
	code: string;
};

type AuthTokenData = {
	firstName?: string;
	avatarUrl?: string;
	accessToken: string;
};

const Avatar = styled(Image)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	boxShadow: theme.shadows[2],
}));

export function AuthCode() {
	const [authCode, setAuthCode] = useState<string | null>(null);
	const sendRef = useRef(0);
	const { isConnected, send, on } = useSocket();
	const theme = useTheme();
	const isReady = Boolean(authCode);
	const [authTokenData, setAuthTokenData] = useState<AuthTokenData | null>(
		null,
	);

	useEffect(() => {
		if (!isConnected) {
			// Disconnected
			setAuthCode(null);

			return;
		}
		const sendId = ++sendRef.current;

		send<CodeResponse>("v1.auth.code", undefined, true).then((response) => {
			if (sendId !== sendRef.current) return;

			setAuthCode(response.code);
		});
	}, [send, isConnected]);

	useEffect(
		() =>
			on("v1.auth.token", (data: AuthTokenData) => {
				Cookie.set("access_token", data.accessToken, {
					sameSite: "Strict",
					secure: true,
					expires: 30,
				});

				setAuthTokenData(data);
			}),
		[on],
	);

	useEffect(() => {
		if (!authTokenData) return;

		setTimeout(() => window.location.reload(), 4000);
	}, [authTokenData]);

	return (
		<Box
			sx={{
				flex: 1,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				gap: "20px",
			}}
		>
			{authTokenData && (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{authTokenData.avatarUrl && (
						<Avatar
							src={authTokenData.avatarUrl}
							width={200}
							height={200}
							alt={authTokenData.firstName ?? "avatar"}
						/>
					)}
				</Box>
			)}
			{isReady && !authTokenData ? (
				<Fragment>
					<Icon
						icon="material-symbols:wifi"
						color={theme.palette.primary.main}
						fontSize={48}
					/>
					<Fade in>
						<Paper
							sx={{
								overflow: "hidden",
							}}
						>
							<QRCode content={authCode} />
						</Paper>
					</Fade>
				</Fragment>
			) : (
				<Icon
					icon="svg-spinners:6-dots-rotate"
					color={theme.palette.primary.main}
					fontSize={56}
				/>
			)}
		</Box>
	);
}
