"use client";

import { Box, Grid, Typography } from "@mui/material";
import Image from "next/image";
import Logo from "./logo.svg";
import { AuthCode } from "./AuthCode";
import { useEffect } from "react";
import Cookies from "js-cookie";

export default function Login() {
	useEffect(() => {
		// Prevent token mismatch window.reload loop (sockets).
		// An invalid token (event if it's expired) may not be
		// removed by the server, so we need to clear it.

		Cookies.remove("access_token");
	}, []);

	return (
		<Grid container flex={1}>
			<Grid item xs={3} display="flex">
				<Box
					sx={(theme) => ({
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flex: 1,
						p: theme.spacing(2),
						boxShadow: theme.shadows[4],
						backgroundColor: theme.palette.background.paper,
					})}
				>
					<Box
						sx={(theme) => ({
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: theme.spacing(3),
						})}
					>
						<Image src={Logo} alt="Transferência na Medicina Logo" />
						<Typography variant="h4" align="center">
							Bem-vindo à <br />
							Smart TV
						</Typography>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={9} display="flex">
				<AuthCode />
			</Grid>
		</Grid>
	);
}
