"use client";

import QRCodeGenerator from "qrcode";
import { useEffect, useRef } from "react";

export function QRCode({ content }: { content: string | null }) {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		if (!canvasRef.current || !content) return;

		QRCodeGenerator.toCanvas(canvasRef.current, content, {
			width: 300,
			color: {
				dark: "#1f1f1f",
				light: "#ffffff",
			},
		});
	}, [content]);

	return <canvas ref={canvasRef} />;
}
