"use client";

import {
	createContext,
	useContext,
	useRef,
	useEffect,
	useState,
	useCallback,
	ReactNode,
} from "react";
import socketIO, { Socket } from "socket.io-client";
import PQueue from "p-queue";
import Cookies from "js-cookie";

const apiUrl = process.env.NEXT_PUBLIC_API_URL as string;

const SocketContext = createContext<{
	on: <Payload>(
		channel: string,
		listener: (payload: Payload) => void,
	) => () => void;
	send: <
		Response,
		Request extends Record<string, unknown> = Record<string, unknown>,
	>(
		channel: string,
		payload?: Request,
		immediate?: boolean,
	) => Promise<Response>;
	isConnected: boolean;
	isIdentified: boolean;
}>({
	on: () => () => void 0,
	send: <R,>() => Promise.resolve(undefined as R),
	isConnected: false,
	isIdentified: false,
});

export function SocketProvider({ children }: { children: ReactNode }) {
	const ioRef = useRef<Socket | null>(null);
	const [isConnected, setIsConnected] = useState(false);
	const [isIdentified, setIsIdentified] = useState(false);
	const connectionQueue = useRef(new PQueue({ autoStart: false }));
	const messagesQueue = useRef(new PQueue({ autoStart: false }));
	const authStartedRef = useRef(false);

	useEffect(() => {
		ioRef.current = socketIO(apiUrl);

		ioRef.current.on("connect", () => {
			setIsConnected(true);

			console.debug("[Socket] Connected.");

			connectionQueue.current.start();
		});

		ioRef.current.on("disconnect", () => {
			connectionQueue.current.pause();
			messagesQueue.current.pause();

			setIsConnected(false);
			setIsIdentified(false);
			authStartedRef.current = false;

			console.debug("[Socket] Disconnected.");
		});

		return () => {
			ioRef.current?.disconnect();
		};
	}, []);

	useEffect(() => {
		const accessToken = Cookies.get("access_token");

		if (!ioRef.current || !accessToken) return;

		if (isConnected && !authStartedRef.current) {
			authStartedRef.current = true;

			ioRef.current.emit(
				"v1",
				"identify",
				{ token: accessToken },
				(err: unknown, response: { ok: boolean }) => {
					if (!err && response.ok) {
						setIsIdentified(true);
						console.debug(`[Socket] Logged.`);

						messagesQueue.current.start();
						console.debug(`[Socket] Started.`);
					} else {
						window.location.reload();
					}
				},
			);
		}
	}, [isConnected]);

	// Only clear on cleanup.
	useEffect(
		() => () => {
			connectionQueue.current?.clear();
			messagesQueue.current?.clear();
		},
		[],
	);

	const on = useCallback(
		<Payload,>(channel: string, listener: (payload: Payload) => void) => {
			// Queue may be paused if socket is disconnected.
			let unsubscribeCalled = false;

			// Use queue to ensure that the listener is
			// added after the socket instance was created.
			connectionQueue.current.add(() => {
				if (!ioRef.current) {
					throw new Error("Socket is not connected.");
				}

				if (!unsubscribeCalled) ioRef.current.on(channel, listener);
			});

			return () => {
				unsubscribeCalled = true;

				ioRef.current?.off(channel, listener);
			};
		},
		[],
	);

	const send = useCallback(
		<
			Response,
			Request extends Record<string, unknown> = Record<string, unknown>,
		>(
			channel: string,
			payload?: Request,
			immediate = false,
		) => {
			return new Promise<Response>((resolve, reject) => {
				const handler = () => {
					if (!ioRef.current) {
						throw new Error("Socket is not connected.");
					}

					// Get event and action from channel.
					const channelParts = channel.split(".");

					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const event = channelParts.shift()!;
					const action = channelParts.join(".");

					ioRef.current.emit(
						event,
						action,
						payload ?? {},
						(err: unknown, response: Response) => {
							if (err) {
								return reject(err), void 0;
							}

							resolve(response);
						},
					);
				};

				immediate ? handler() : messagesQueue.current.add(handler);
			});
		},
		[],
	);

	return (
		<SocketContext.Provider value={{ on, send, isConnected, isIdentified }}>
			{children}
		</SocketContext.Provider>
	);
}

export function useSocket() {
	return useContext(SocketContext);
}
